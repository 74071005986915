import styled from '@emotion/styled'

const getRule = (ruleName, defaultRule) => (props) => {
  const foundRule = Object.keys(props).find((key) => key.startsWith(ruleName))
  if (!foundRule || !props[foundRule]) {
    return defaultRule
  }

  const rule = foundRule
    .match(/[A-Z][a-z]+/g)
    .join('-')
    .toLowerCase()
  return rule
}

const FlexBox = styled.div`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  flex-direction: ${getRule('direction', 'row')};
  flex-wrap: ${getRule('wrap', 'nowrap')};
  justify-content: ${getRule('justify', 'flex-start')};
  align-items: ${getRule('items', 'stretch')};
  align-content: ${getRule('content', 'stretch')};
  flex: ${({ flex }) => flex || 'initial'};
  order: ${({ order }) => order || 'initial'};
`

export default FlexBox
